// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedEmployee: null,
  currentUser: null,
  states: [],
  currentCompany:null,
  usersBirthday:[],
  usersBirthdayMonth:[],
  usersTotal:0,
  usersUpcommingAnniervsaries:[],
  usersUpcommingAnniervsariesThree:[],
  userNews:[],
  usersByJob:[],
  usersByRange:[],
  usersByArea:[],
  myCapacitations:[]
}

const employees = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_AVAILABLE_USERS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USERS_EXPORT':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_OFFICE':
      return { ...state }
    case 'GET_USER':
      return { ...state, selectedEmployee: action.selectedEmployee, currentUser: action.selectedEmployee}
    case 'GET_CURRENT_COMPANY':
      return { ...state, currentCompany: action.currentCompany}
    case 'GET_USER_BIRTHDAY':
      return { ...state, usersBirthday: action.data}
    case 'GET_USER_BIRTHDAY_MONTH':
      return { ...state, usersBirthdayMonth: action.data}
    case 'GET_USERS_TOTAL':
      return { ...state, usersTotal: action.data}
    case 'GET_UPCOMMING_ANNIVERSARIES':
      return { ...state, usersUpcommingAnniervsaries: action.data}
    case 'GET_MY_CAPACITATIONS':
      return { ...state, myCapacitations: action.data}
    case 'GET_UPCOMMING_ANNIVERSARIES_THREE':
      return { ...state, usersUpcommingAnniervsariesThree: action.data}
    case 'GET_USERS_BY_JOB':
      return { ...state, usersByJob: action.data}
    case 'GET_USERS_BY_RANGE':
      return { ...state, usersByRange: action.data}
    case 'GET_USERS_BY_AREA':
      return { ...state, usersByArea: action.data}
    case 'GET_NEW_USERS':
      return { ...state, userNews: action.data}
    case 'GET_CURRENT_USER':
      return { ...state, currentUser: action.data}
    case 'GET_STATES':
      return { ...state, states: action.states }
    case 'EDIT_EMPLOYEE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default employees
