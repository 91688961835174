// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import companies from '@src/views/company/store/reducer'
import roles from '@src/views/permissions/store/reducer'
import jobs from '@src/views/job/store/reducer'
import providerCompany from '@src/views/provider_company/store/reducer'
import providers from '@src/views/provider/store/reducer'
import documents from '@src/views/document/store/reducer'
import offices from '@src/views/office/store/reducer'
import employees from '@src/views/employee/store/reducer'
import payments from '@src/views/payment/store/reducer'
import inventory_categories from '@src/views/inventory-category/store/reducer'
import inventory_providers from '@src/views/inventory-provider/store/reducer'
import inventory_project from '@src/views/inventory-project/store/reducer'
import inventory_actives from '@src/views/inventory-active/store/reducer'
import holidays from '@src/views/holiday/store/reducer'
import paysheets from '@src/views/paysheet/store/reducer'
import paysheetReportDetails from '@src/views/paysheet-detail/store/reducer'
import capacitations from '@src/views/capacitation/store/reducer'
import drives from '@src/views/drive/store/reducer'
import provider_questions from '@src/views/provider-questions/store/reducer'
import advertisements from '@src/views/advertisements/store/reducer'
import logs from '@src/views/log/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import products from '@src/views/product/store/reducer'
import credits from '@src/views/credit/store/reducer'
import groupFields from '@src/views/group-fields/store/reducer'


import dataTables from '@src/views/tables/data-tables/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  companies,
  navbar,
  layout,
  invoice,
  calendar,
  dataTables,
  roles,
  jobs,
  documents,
  offices,
  employees,
  logs,
  drives,
  provider_questions, 
  advertisements,
  products,
  credits,
  groupFields
})

export default rootReducer
