// ** Initial State
const initialState = {
  allData: [],
  data: [],
  allDataDaily: [],
  dataDaily: [],
  cols: [],
  total: 1,
  params: {},
  selectedProduct: null,
  fields: [],
  documents: [],
  steps:[],
  allSteps:[],
  currentStep:null,
  selectedCredit:null,
  creditsByStep: [],
  error: false,
  errorAdd: false,
  sended: false
}

const credits = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STEPS':
      return { ...state, steps: action }
    case 'GET_ALL_STEPS':
      return { ...state, allSteps: action }
    case 'GET_CREDITS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        error: action.error
      }
    case 'RESET':
      return initialState //Always return the initial state
    case 'GET_DAILY':            
      return {
        ...state,
        allData: action.data.data,
        data: action.data.data,
        cols: action.data.columns,
        total: action.totalPages,
        params: action.params,
        error: action.error
      }
    case 'ADD_CREDIT':
      return { ...state, errorAdd: action.error, sended: action.sended }
    case 'DELETE_PRODUCT':
      return { ...state }
    case 'GET_PRODUCT':
      return { ...state, selectedProduct: action.selectedProduct }
    case 'GET_STEP':
      return { ...state, currentStep: action.currentStep }
    case 'GET_CREDIT':
      return { ...state, selectedCredit: action.selectedCredit, error: action.error }
    case 'GET_FIELDS':
      return { ...state, fields: action.fields }
    case 'GET_DOCUMENTS':
      return { ...state, documents: action.documents }
    case 'EDIT_COMPANY':
      return { ...state }
    case 'ERROR_DUPLICATE_VALUE':
      return { ...state, error: action.error }
    case 'GET_CREDITS_BY_STEP':
      return { ...state, creditsByStep: action.data}
    default:
      return { ...state }
  }
}
export default credits
